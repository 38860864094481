import { combineReducers } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { RESET_STATE_ACTION_TYPE } from './actions';

import { authSlice } from './slices/auth.slice';
import { utilsSlice } from './slices/utils.slice';
import { transactionSlice } from './slices/transaction.slice';

import { authApi } from './api/auth.api';
import { utilsApi } from './api/utils.api';
import { transactionApi } from './api/transaction.api';


export const authReducer = persistReducer({
    key: 'auth',
    storage,
}, authSlice.reducer);

export const utilsReducer = persistReducer({
    key: 'utils',
    storage,
}, utilsSlice.reducer);

export const transactionReducer = persistReducer({
    key: 'transaction',
    storage,
}, transactionSlice.reducer);

const reducers = {
    _auth: authReducer,
    _utils: utilsReducer,
    [authApi.reducerPath]: authApi.reducer,
    [utilsApi.reducerPath]: utilsApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
    //   if (action.type === RESET_STATE_ACTION_TYPE) {
    //     state = {} as RootState;
    //   }

    return combinedReducer(state, action);
};

export type RootState = ReturnType<typeof combinedReducer>;
