import { Dropdown, Tag } from 'antd';
import { Status } from 'components';
import { axius, helpers, images, types } from 'helpers';

export const findNetworkAccount = (network: string) => {
    switch (network) {
        case 'MTN':
            return images.IconMtn;
        case 'VOD':
            return images.IconVodafone;
        case 'VISA':
            return images.Visa;
        case 'MASTERCARD':
            return images.MasterCard;
        case 'BEP20':
        case 'ERC20':
        case 'TRC20':
            return images.USDT;
    }
    return images.IconBank;
}

export const findCurrencyImage = (currency: types.Currencies) => {
    switch (currency) {
        case types.Currencies.GHS:
            return images.Ghana;
        case types.Currencies.NGN:
            return images.Nigeria;
        case types.Currencies.USDT:
            return images.USDT;
        case types.Currencies.UGX:
            return images.Uganda;
        case types.Currencies.KES:
            return images.Kenya;
        case types.Currencies.XOF:
            return images.IvoryCoast;
        case types.Currencies.XAF:
            return images.Cameroon;
        case types.Currencies.TZS:
            return images.Tanzania;
        case types.Currencies.USD:
            return images.UnitedStates;
    }
}

export const findNetworkOrBank = (row: types.Transaction) => {
    const config = JSON.parse(helpers.getStorageJson('utils').config);
    return (config.banks[row.currency] || []).find((bank: any) => bank.code === row.network)?.name || helpers.ucWords(row.network);
}

export const Transactions = {
    heading: ['Reference', 'Amount', 'Fee', 'Customer number', 'Customer name', 'Network', 'Status', 'Date', ''],
    data: (data: types.Transaction[]) => data.map(row => {
        return {
            id: row.id,
            reference: row.id,
            amount: (
                <span className={`font-medium ${row.type === types.TrxTypes.DEBIT ? 'success' : 'danger'}`}>
                    {row.type === types.TrxTypes.DEBIT ? '+' : '-'} {helpers.format.currency(row.metadata?.rate_amount || row.amount, row.metadata?.currency_destination || row.currency)}
                </span>
            ),
            fee: helpers.format.currency(row.fee, row.currency),
            number: helpers.format.phoneNumber(row.customer?.accountNumber),
            customer: row.customer?.accountName,
            network: (
                <span className="flex items-center">
                    <img
                        src={findNetworkAccount(row.network)}
                        alt={row.network}
                        className="mr-2 border rounded-lg p-1"
                        style={{ height: 25 }}
                    />
                    {findNetworkOrBank(row)}
                </span>
            ),
            status: <Status status={row.status} label={types.TrxStatusLabels[row.status]} />,
            date: helpers.format.date(row.created_at),
            actions: {
                clickable: false,
                value: (
                    <>
                        <Dropdown
                            trigger={['click']} placement="bottom"
                            menu={{
                                items: [
                                    {
                                        key: 'hook',
                                        label: (
                                            <div onClick={() => axius.post(`transactions/${row.id}/resend-webhook`)}>
                                                Resend webhook
                                            </div>
                                        ),
                                    },
                                ],
                            }}
                        >
                            {/* <Button type="text" shape="circle" icon={<i className="fa fa-ellipsis-hs text-xl">...</i>} /> */}
                            <i className="fa fa-ellipsis-hs text-3xl">...</i>
                        </Dropdown>
                    </>
                ),
            }
        }
    }),
    export: (data: types.Transaction[], type?: string) => (data || []).map((a: types.Transaction) => {
        return {
            'Business': a.business_name,
            'Reference': a.id,
            'Amount': a.amount,
            'Fee': a.fee - (a.metadata?.smsFee || 0),
            'SMS Fee': a.metadata?.smsFee || 0,
            'Fee Bearer': a.bear_fee,
            'Amount After Fee': (a.bear_fee === 'business' ? a.amount - a.fee : a.amount),
            'Customer Name': a.customer.accountName || '',
            'Customer Number': a.customer.accountNumber,
            'Sender Name': a.metadata?.senderName || a.metadata?.sender_name || '',
            'Channel': types.TrxChannelsLabels[a.channel as keyof typeof types.TrxChannelsLabels],
            'Network': findNetworkOrBank(a),
            'Status': types.TrxStatusLabels[a.status as keyof typeof types.TrxStatusLabels],
            'Provider': a.provider,
            'Provider Message': a.provider_message || '',
            'Reason': a.reason,
            'Type': type || (a.type === types.TrxTypes.DEBIT ? 'Collection' : 'Payout'),
            'Currency': a.currency,
            'Business Reference': (a as any).reference_business || '',
            'Date': a.created_at,
            'Opening Balance': a.opening_balance,
            'Closing Balance': a.closing_balance,
        };
    }),
};

export const Business = {
    heading: ['Name', 'Email', 'Phone number', 'Customer name', 'Status', 'Date'],
    data: (data: types.Business[]) => data.map(row => {
        const teams = (row.teams || {}).length > 0 ? row.teams : [{ user: {} as types.User }];
        return {
            id: row.id,
            businessName: row.name,
            email: teams[0].user.email,
            phone_number: helpers.format.phoneNumber(teams[0].user.phone_number),
            userName: `${teams[0].user.first_name} ${teams[0].user.last_name}`,
            status: <Status status={row.status} label={types.TeamStatusLabels[row.status]} />,
            date: helpers.format.date(row.created_at),
        };
    })
};

export const BusinessBalance = {
    heading: ['Name', 'Balance'],
    data: (data: types.Business[]) => data.map(row => {
        return {
            id: row.id,
            businessName: row.name,
            balance: row.wallets.map((w, i) => (
                <div key={w.id} className={`flex items-center ${i > 0 ? 'mt-2' : ''}`}>
                    <div className="">{helpers.format.currency(w[`balance`], w.currency)}</div>
                </div>
            )),
        };
    })
};

export const Team = {
    heading: ['Name', 'Email address', 'Phone number', 'Role', 'Status', 'Date'],
    data: (data: types.User[], user: types.User) => data.map(row => {
        return {
            id: row.id,
            name: `${row.first_name} ${row.last_name}`,
            email: <div>{row.email} {row.id === user.id ? <Tag>You</Tag> : ''}</div>,
            phoneNumber: helpers.format.phoneNumber(row.phone_number),
            role: <Tag>{row.admin_role ? row.admin_role.name : 'Odogwu'}</Tag>,
            status: <Status status={row.status || types.TeamStatus.ACCEPTED} label={types.TeamStatusLabels[row.status || types.TeamStatus.ACCEPTED]} />,
            date: helpers.format.date(row.created_at),
        };
    })
};

export const AdminRoles = {
    heading: ['Name', 'Permission count', 'Date'],
    data: (data: types.AdminRoles[]) => data.map(row => {
        return {
            id: row.id,
            name: row.name,
            count: helpers.format.number(row.permissions.length, 0),
            date: helpers.format.date(row.created_at),
        };
    })
};


export const Logs = {
    heading: ['Details'],
    data: (data: types.Logs[]) => data.map(row => {
        const admin = row.details.split('"')[1];
        const changes = row.details.split(admin);
        return {
            id: row.id,
            details: (
                <div className="text-sm" style={{ wordWrap: 'break-word', width: 150, fontFamily: 'Courier' }}>
                    <div><span className="font-semibold">Admin:</span> {admin}</div>
                    <div><span className="font-semibold">Change(s):</span> {changes[1].split('" changed').join('')}</div>
                    <div><span className="font-semibold">Business:</span> {changes[1].split(' for ')[1]?.split('"').join('')}</div>
                    <div><span className="font-semibold">Date:</span> {helpers.format.date(row.created_at)}</div>
                </div>
            ),
        };
    })
};

export const VirtualAccount = {
    heading: ['Name', 'Code', 'Number', 'Currency', 'Provider', 'Date'],
    data: (data: types.VirtualAccount[]) => data.map(row => {
        return {
            id: row.id,
            name: `${row.provider === types.TrxProviders.PAYAZA ? row.account_name : row.bank_name} (${row.stage})`,
            code: row.bank_code,
            number: row.account_number,
            currency: row.currency,
            provider: row.provider,
            date: helpers.format.date(row.created_at),
        };
    })
};

export const SettlementAccount = {
    heading: ['Name', 'Code', 'Number', 'Currency', 'Details', 'Date'],
    data: (data: types.SettlementAccount[]) => data.map(row => {
        return {
            id: row.id,
            name: `${row.account_name}`,
            code: row.network,
            number: row.account_number,
            currency: row.currency,
            details: (
                <div>
                    {Object.keys(row.details).map(sad => (
                        <div><span className="capitalize">{sad.split('_').join(' ')}</span>: {row.details[sad]}</div>
                    ))}
                </div>
            ),
            date: helpers.format.date(row.created_at),
        };
    })
};