import React from 'react';

interface Props {
    value: string;
    buttons: { label?: string; value: string; }[];
    onChange: (value: string) => void;
}
const ButtonGroup = ({ value, buttons, onChange }: Props) => {

    return (
        <React.Fragment>
            <div id="ButtonGroup" className="border border-gray-200 flex bg-gray-100">
                {buttons.map(button => (
                    <div
                        key={button.value}
                        className={`button flex justify-center items-center cursor-pointer text-xs ${value === button.value ? 'bg-white' : ''}`}
                        onClick={() => onChange(button.value)}
                    >
                        {button.label || button.value}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default ButtonGroup;