import React from 'react';
import { Input } from 'antd';

interface Props {
    size?: number;
    resend?: boolean;
    onChange?: (code: string) => void;
    onPressEnter?: () => void;
}

const CodeInput = (props: Props) => {

    const size = props.size || 6;
    const [value, setValue] = React.useState(Array(size).fill(''));

    React.useEffect(() => {
        setTimeout(() => {
            activateInput(0);
        }, 100);
        // eslint-disable-next-line
    }, []);

    const activateInput = (i: number) => {
        for (let a = 0; a < size; a++) {
            document.getElementById(`CodeInput_${a}`)?.setAttribute('disabled', 'disabled');
        }
        document.getElementById(`CodeInput_${i}`)?.removeAttribute('disabled');
        document.getElementById(`CodeInput_${i}`)?.focus();
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
        const v = e.target.value;
        const updatedValue = [...value];
        // Allow only single-digit numeric inputs

        if (/^[0-9]$/.test(v)) {
            updatedValue[i] = v;
            setValue(updatedValue);

            // Move the focus to the next available input field
            let nextInputIndex = i + 1;
            while (nextInputIndex < size && updatedValue[nextInputIndex]) {
                nextInputIndex++;
            }
            if (nextInputIndex < size) {
                activateInput(nextInputIndex);
            } else {
                // If all inputs are filled, blur the current input field to hide the keyboard
                e.target.blur();
                activateInput(size - 1);
            }

            props.onChange && props.onChange(updatedValue.join(''));
        }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, i: number) => {
        // Handle Backspace key press
        if (e.key === 'Backspace') {
            const updatedValue = [...value];

            // Move the focus to the previous input field
            if (i > 0) {
                activateInput(updatedValue[i] ? i : i - 1);
            }

            updatedValue[i] = '';
            setValue(updatedValue);
            props.onChange && props.onChange(updatedValue.join(''));
        } else if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
            e.preventDefault();
            if (e.key === 'ArrowLeft') {
                // Move to the previous input field
                if (i > 0) {
                    activateInput(i - 1);
                }
            } else if (e.key === 'ArrowRight') {
                // Move to the next input field
                if (i < 5) {
                    let nextInputIndex = i + 1;
                    while (nextInputIndex < size && value[nextInputIndex]) {
                        nextInputIndex++;
                    }
                    if (nextInputIndex < size) {
                        activateInput(nextInputIndex);
                    }
                }
            }
        } else if (['', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
            const updatedValue = [...value];
            if (updatedValue[i] !== '') {
                if (i < 5) {
                    let nextInputIndex = i + 1;
                    while (nextInputIndex < size && value[nextInputIndex]) {
                        nextInputIndex++;
                    }
                    if (nextInputIndex < size) {
                        activateInput(nextInputIndex);
                    }
                }
            }
        }
    }

    const pasteCode = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        // retrieves the pasted data from the clipboard by using getData
        const pasteData = e.clipboardData.getData('text');

        // extract first six chars pasted data
        const code = pasteData.slice(0, size);
        const updatedValue = Array(size).fill('').map((_, index) => (/^[0-9]$/.test(code[index]) ? code[index] : ''));
        setValue(updatedValue);
        props.onChange && props.onChange(updatedValue.join(''));
        activateInput(size - 1);
    }

    // 123456

    return (
        <React.Fragment>
            <div id="CodeInput" className="flex justify-between">
                {[...Array(size)].map((_, i) => {
                    const iValue = value[i];
                    return (
                        <Input
                            id={`CodeInput_${i}`}
                            key={i}
                            type="text" // Change the type to "text" to allow single-character inputs
                            value={iValue}
                            onChange={(e) => onChange(e, i)}
                            onKeyDown={(e) => onKeyDown(e, i)} // Added onKeyDown event listener
                            maxLength={1}
                            onPaste={pasteCode}
                            autoComplete="off"
                            onPressEnter={() => {
                                if (value.filter(v => v).length === size && props.onPressEnter) {
                                    props.onPressEnter();
                                }
                            }}
                        />
                    );
                })}
            </div>

            {props.resend && (
                <div className="mt-4 text-sm text-center text-gray-400">
                    Didn't receive the code? <span className="primary">Resend Code</span>
                </div>
            )}
        </React.Fragment>
    );
};

export default CodeInput;
