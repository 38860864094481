import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config, types } from 'helpers';
import { RootState } from '../reducer';


export const transactionApi = createApi({
    reducerPath: 'transactionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.server,
        prepareHeaders: (headers, { getState, endpoint }) => {
            const state = getState() as RootState;
            const env = state._utils.env;
            let token = state._auth.token;
            const business = state._auth.business.keys[0];
            if (['collectMomo'].includes(endpoint)) {
                token = (env === 'test' ? business.test_key : business.live_key);
            }
            if (token) {
                headers.set(`Authorization`, `${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        allTransactions: build.query<
            { meta: types.DataMeta; data: types.Transaction[]; },
            { cursor?: string; businessId?: string; apiKey?: string; cache?: number; type?: types.TrxTypes; }
        >({
            query: (params) => ({
                url: `transactions`,
                method: 'GET',
                params,
            }),
        }),
        overview: build.query<
            types.TrxOverview,
            { to: string; from: string; dates: string; apiKey: string; cache?: any; businessId: string; }
        >({
            query: (params) => ({
                url: `transactions/overview`,
                method: 'GET',
                params,
            }),
        }),
        collectMomo: build.mutation<types.Transaction, types.DebitMomo>({
            query: (body) => ({
                url: `collections/mobilemoney`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useAllTransactionsQuery, useOverviewQuery, useCollectMomoMutation } = transactionApi;
