import React from 'react';
import { Button, Drawer, Form, Input, notification, Select } from 'antd';
import { helpers, images, types } from 'helpers';
import { useTopUpBusinessMutation } from 'Store/api/auth.api';

interface Props {
    row: types.Business;
    open: boolean;
    onClose: () => void;
    onSuccess?: (e: types.Business) => void;
}
const FormBusinessBalance = ({ open, row, onClose, onSuccess }: Props) => {

    const [form] = Form.useForm();

    const [topUp, { isLoading, isError, isSuccess, error, data }] = useTopUpBusinessMutation();

    React.useEffect(() => {
        if (row?.id && open) {
            form.setFieldsValue(row);
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [row, open]);

    React.useEffect(() => {
        isError && notification.error({ message: (error as any).data?.message || ((error as any).data || []).join(', ') });
        if (isSuccess) {
            notification.success({ message: 'Business changes saved' });
            onSuccess && onSuccess(data as types.Business);
            onClose();
        }
        // eslint-disable-next-line
    }, [isError, isSuccess]);

    const submit = async (v: { amount: number }) => {
        v.amount = +v.amount;
        await topUp({ ...v, id: row?.id });
    }

    const rules = [{ required: true, message: 'This field is required' }];

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={380}
                closable={true}
                onClose={onClose}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={submit}>
                    <div className="flex justify-between items-center mb-8">
                        <div className="text-xl font-semibold">
                            Top up business
                        </div>
                        <div
                            className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                            onClick={onClose}
                        >
                            <img src={images.Close} alt="Close" />
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-2">
                            <Form.Item name="currency" label="Currency" rules={rules}>
                                <Select size="large" showSearch optionFilterProp="children" placeholder="Select currency" disabled={isLoading}>
                                    {Object.keys(types.Currencies)
                                        .filter(currency => helpers.businessHasAccess(row, currency))
                                        .map(option => (
                                            <Select.Option key={option} value={option}>
                                                ({option}) - {types.CurrenciesLabels[option as unknown as types.Currencies]}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-span-2">
                            <Form.Item name="amount" label="Amount" help="Amount can be negative" rules={rules}>
                                <Input size="large" type="number" autoComplete="off" disabled={isLoading} />
                            </Form.Item>
                        </div>
                        <div className="col-span-2 mt-8">
                            <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default FormBusinessBalance;