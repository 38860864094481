import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading, Tabs } from 'components';
import { helpers } from 'helpers';

const SettingsConfig = React.lazy(() => import('./Config'));
const SettingsProfile = React.lazy(() => import('./Profile'));
const SettingsPassword = React.lazy(() => import('./Password'));

const Settings = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const items = [
        { key: 'config', label: 'Configurations', children: <SettingsConfig />, permission: 'CONFIG', },
        { key: 'profile', label: 'Profile', children: <SettingsProfile />, },
        { key: 'password', label: 'Password', children: <SettingsPassword />, },
    ].filter(item => !item.permission || helpers.hasPermission(item.permission));

    const current = location.pathname.split('/')[2] || items[0].key;

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <Tabs
                    type="card"
                    value={current}
                    items={items}
                    onChange={(e) => navigate(e)}
                />
            </React.Suspense>
        </React.Fragment>
    );
}

export default Settings;