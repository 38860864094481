import { helpers } from 'helpers';

// ::: auth pages
import Auth from 'pages/Auth/_Auth';

// ::: app pages
import Logs from 'pages/Logs';
import Team from 'pages/Team/_Team';
import Business from 'pages/Business/_Business';
import Settings from 'pages/Settings/_Settings';
import Reports from 'pages/Reports/_Reports';
import Dashboard from 'pages/Dashboard/_Dashboard';
import Customers from 'pages/Business/Customers';
import Statement from 'pages/Statement';
import Settlements from 'pages/Settlements';

const routes = [
    { path: `/auth/*`, element: Auth, auth: false, },

    { path: `/`, element: Dashboard, auth: true, },
    { path: `/settings/*`, element: Settings, auth: true, },
    { path: `/reports/*`, element: Reports, auth: true, },

    { path: `*`, element: Dashboard, auth: true, },

    { path: `/team/*`, element: Team, auth: true, role: 'TEAM', },
    { path: `/merchants`, element: Customers, auth: true, role: 'CUSTOMERS', },
    { path: `/merchants/*`, element: Business, auth: true, role: 'CUSTOMERS_DETAILS', },
    { path: `/compliance`, element: Customers, auth: true, role: 'COMPLIANCE', },
    { path: `/compliance/*`, element: Business, auth: true, role: 'CUSTOMERS_DETAILS', },
    { path: `/statement`, element: Statement, auth: true, role: 'STATEMENT', },
    { path: `/settlements`, element: Settlements, auth: true, role: 'SETTLEMENTS', },
    { path: `/logs`, element: Logs, auth: true, role: 'LOGS', },
].filter(item => !item.role || helpers.hasPermission(item.role));

export default routes;