import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { images } from 'helpers';
import { Loading } from 'components';

const Otp = React.lazy(() => import('./Otp'));
const Login = React.lazy(() => import('./Login'));
const Reset = React.lazy(() => import('./Reset'));

const Auth = () => {

    const routes = [
        { path: `2fa`, element: Otp, },
        { path: `login`, element: Login, },
        { path: `reset`, element: Reset, },
        { path: `*`, element: Login, },
    ];

    return (
        <React.Fragment>
            <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-span-7 xl:col-span-6 h-screen bg-white">
                    <div className="AuthMobileTop block lg:hidden xl:hidden mb-12" />
                    <React.Suspense fallback={<Loading full spinning={true} />}>
                        <Routes>
                            {routes.map(route => (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={<route.element />}
                                />
                            ))}
                        </Routes>
                    </React.Suspense>
                </div>
                <div className="lg:col-span-5 xl:col-span-6 h-screen bg-primary hidden lg:block xl:block">
                    <div className="flex justify-center items-center h-screen">
                        <div>
                            <img src={images.Auth} alt="Open Gateway mini dashboard on onboarding" />
                            <div className="text-center text-white font-medium">
                                Fast and secure ways to pay and <br /> receive money
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Auth;