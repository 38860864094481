import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { helpers } from 'helpers';
import { _logout } from 'Store/slices/auth.slice';

export const unauthenticatedMiddleware: Middleware = ({ dispatch }) =>
  (next) => (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      helpers.delStorage('defaultPassword');
      notification.error({ message: 'Unauthorized' });
      dispatch(_logout());
    }

    return next(action);
  };
