import React from 'react';
import { Button, Checkbox, Drawer, Form, notification } from 'antd';
import { images, types } from 'helpers';
import { useEditBusinessMutation } from 'Store/api/auth.api';
import { useDispatch } from 'react-redux';
import { _setUtils } from 'Store/slices/utils.slice';


interface Props {
    row?: types.Business;
    open: boolean;
    onClose: () => void;
    onSuccess?: (e: types.Business) => void;
}
const FormBusiness = ({ open, row, onClose, onSuccess }: Props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [editBusiness, { isLoading, isError, isSuccess, error, data }] = useEditBusinessMutation();

    React.useEffect(() => {
        if (row?.id && open) {
            form.setFieldsValue({
                preferences: {
                    permissions: row?.preferences?.permissions || [],
                }
            });
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [row, open]);

    React.useEffect(() => {
        isError && notification.error({ message: (error as any).data?.message || ((error as any).data || []).join(', ') });
        if (isSuccess) {
            notification.success({ message: 'Business permissions saved' });
            dispatch(_setUtils({ key: 'meCache', value: data?.updated_at }));
            onSuccess && onSuccess(data as types.Business);
            onClose();
        }
        // eslint-disable-next-line
    }, [isError, isSuccess]);

    const submit = async (v: Partial<types.Business>) => {
        if (row) {
            v['id'] = row.id;
            v['preferences'] = { ...row.preferences, ...v.preferences, };
            v['callback_url'] = row?.callback_url || 'https://google.com';
            if (row?.id) {
                await editBusiness({ ...row, ...v, });
            }
        }
    }

    const options = [
        { label: 'Collections', value: 'debit' },
        { label: 'Process Collections', value: 'debit_process' },
        { label: 'Payouts', value: 'credit' },
        { label: 'Process Payouts', value: 'credit_process' },
        ...Object.keys(types.Currencies).map(item => {
            return { label: `${item} wallet`, value: item };
        }),
    ];

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={480}
                closable={true}
                onClose={onClose}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={submit}>
                    <div className="flex justify-between items-center mb-8">
                        <div className="text-xl font-semibold">Edit business' permissions</div>
                        <div
                            className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                            onClick={onClose}
                        >
                            <img src={images.Close} alt="Close" />
                        </div>
                    </div>

                    <div className="grids grid-cols-2s gap-4s">
                        <Form.Item name={['preferences', 'permissions']}>
                            <Checkbox.Group options={options} />
                        </Form.Item>

                        <div className="col-span-2 mt-8">
                            <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default FormBusiness;