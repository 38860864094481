import React from 'react';
import { Button } from 'antd';
import { useTypedSelector } from 'Store/_Store';

interface Props {
    meta: {
        next: string;
        page: number;
        total: number;
        previous: string;
    };
    navigate: (cursor: string) => void;
}
const Pagination = ({ meta, navigate }: Props) => {

    const { _utils: { limit } } = useTypedSelector((state) => state);

    return (
        <React.Fragment>
            <div id="Pagination">
                <div className="flex justify-between items-center">
                    <div>
                        <Button
                            type="default"
                            disabled={!meta.previous}
                            onClick={() => navigate(meta.previous)}
                        >
                            Previous
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                            type="default"
                            disabled={!meta.next}
                            onClick={() => navigate(meta.next)}
                        >
                            Next
                        </Button>
                    </div>
                    {(meta.previous || meta.next) && (
                        <div>
                            Page {meta.page} of {Math.round(meta.total / limit) || 1}
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}


export default Pagination;