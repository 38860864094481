import React from 'react';
import { images } from 'helpers';

interface Props {
    icon?: boolean;
    color: 'warning' | 'success' | 'danger';
    children: any;
    className?: string;
}
const Alert = ({ icon, color, children, className }: Props) => {
    icon = typeof icon === 'undefined' ? true : icon;

    return (
        <React.Fragment>
            <div id="Alert" className={`${icon && 'flex items-center'} font-medium ${color} ${className}`}>
                {icon && (
                    <img src={images.Alert} alt="Alert" className="mr-2" />
                )}
                {children}
            </div>
        </React.Fragment>
    );
};

export default Alert;