import React from 'react';
import { Spin } from 'antd';
import { LoadingMin } from './Loading';
import { images } from 'helpers';

interface Props {
    data: any;
    loading: boolean;
    heading: string[];
    onClick?: (row: any) => void;
    loadingHeight?: number;
}
const Table = ({ data, loading, heading, onClick, loadingHeight }: Props) => {

    const thClass = 'border-gray-200 p-5 border-ts text-left font-semibold text-gray-600s text-sm bg-[#001717] bg-opacity-80s text-white';
    const tdClass = 'border-gray-200 p-5 text-gray-600 border-t text-sm';

    return (
        <React.Fragment>
            {loading ? (
                <div className="bg-gray-50s rounded-bl-xl rounded-br-xl borders">
                    <Spin spinning={true} indicator={<div className="rounded-xl flex justify-centers items-center"><LoadingMin size={35} /></div>}>
                        <div style={{ height: loadingHeight || 525 }} />
                    </Spin>
                </div>
            ) : (
                <div id="Table" className="bg-white rounded-b-xl borders p-4s border-t">
                    <table className="w-full p-4s">
                        {(data || []).length > 0 && (
                            <thead className="">
                                <tr>
                                    {heading.map((th) => (
                                        <th key={th} className={`${thClass}`}>{th}</th>
                                    ))}
                                </tr>
                            </thead>
                        )}
                        <tbody className="">
                            {(data || []).length === 0 && (
                                <tr>
                                    <td colSpan={15} align="center" className={`${tdClass} border-t-0`} style={{ height: 371 }}>
                                        <img src={images.EmptyTable} alt="EmptyTable" />
                                        <div className="text-gray-500 font-light">No transactions to display</div>
                                    </td>
                                </tr>
                            )}
                            {(data || []).length > 0 && (data || []).map((row: any, i: number) => (
                                <tr key={`td-${i}`} className={`hover:bg-gray-50 transi ${onClick ? 'cursor-pointer' : ''}`}>
                                    {Object.keys(row).map(rowKey => {
                                        const clickable = typeof row[rowKey] !== 'object' || (typeof row[rowKey] === 'object' && (typeof row[rowKey].clickable === 'undefined' || row[rowKey].clickable));
                                        return (
                                            rowKey !== 'id' && (
                                                <td
                                                    key={rowKey}
                                                    className={`${tdClass}`}
                                                    onClick={() => onClick && clickable && onClick(row)}
                                                >
                                                    {typeof row[rowKey] === 'object' ? (row[rowKey].value || row[rowKey]) : row[rowKey]}
                                                </td>
                                            )
                                        )
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </React.Fragment>
    );
};

export default Table;