import React from 'react';
import { axius, helpers, types } from 'helpers';
import { Balances, Loading, Volumes } from 'components';

const DashChart = React.lazy(() => import('./Chart'));
const DashCustomers = React.lazy(() => import('./Customers'));

const Dashboard = () => {

    const [wallets, setWallets] = React.useState<types.Wallet[]>([]);
    const [currency, setCurrency] = React.useState<types.Currencies>(types.Currencies.GHS);
    const [settlements, setSettlements] = React.useState<types.Transaction[]>([]);

    React.useEffect(() => {
        Promise.all([
            axius.get(`business/wallets`),
            axius.get(`transactions`, { apiKey: 'live', status: types.TrxStatus.INITIATED, type: types.TrxTypes.SETTLEMENT }),
        ]).then(res => {
            res[0].status === 200 && setWallets(Object.keys(types.Currencies).map(currency => {
                return {
                    id: currency,
                    currency: currency as types.Currencies,
                    balance: (res[0].data as types.Wallet[])
                        .filter(item => item.currency === currency)
                        .reduce((accumulator: number, item) => accumulator + +item.balance, 0),
                    businessId: '',
                    balance_test: 0,
                };
            }).filter(w => w.balance > 0));
            res[1].status === 200 && setSettlements(res[1].data.data);
        });
    }, []);

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <div>
                    <Volumes />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-12 lg:col-span-12">
                            {/* <div className="bg-white px-6 py-8 border border-gray-200 rounded-xl mb-3">
                                <div className="text-gray-400 font-light mb-2 text-sm">Transaction volume</div>
                                <div className="font-semibold text-2xl">
                                    {helpers.format.currency(overview.totalAmount)}
                                </div>
                            </div> */}
                            {/* <div className="bg-white px-6 py-8 border border-gray-200 rounded-xl mb-3">
                                <div className="border-b pb-6">
                                    <div className="text-gray-400 font-light mb-2 text-sm">Total collections</div>
                                    <div className="font-semibold text-2xl">
                                        {helpers.format.currency(
                                            (Object.values(overview.totalsDebit) as number[] || [])
                                                .reduce((accumulator: number, amount) => accumulator + amount, 0)
                                        )}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <div className="text-gray-400 font-light mb-2 text-sm">Total payouts</div>
                                    <div className="font-semibold text-2xl">
                                        {helpers.format.currency(
                                            (Object.values(overview.totalsCredit) as number[] || [])
                                                .reduce((accumulator: number, amount) => accumulator + amount, 0)
                                        )}
                                    </div>
                                </div>
                            </div> */}
                            <Balances business={{ wallets }} onChange={setCurrency} />
                        </div>
                        <div className="col-span-12 md:col-span-3 lg:col-span-3 mb-4">
                            {helpers.hasPermission('SETTLEMENTS') && (
                                <div className="bg-white px-6 py-8 border_ border-gray-200 rounded-xl mb-3s">
                                    <div className="text-gray-400 font-light mb-2 text-sm">Settlements pending</div>
                                    <div className="font-semibold text-2xl">
                                        {helpers.format.currency(
                                            settlements
                                                .filter(item => item.currency === types.Currencies.GHS)
                                                .reduce((accumulator: number, item) => accumulator + +item.amount, 0)
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-span-12 md:col-span-12 lg:col-span-12">
                            <DashChart currency={currency} />
                        </div>
                        {helpers.hasPermission('CUSTOMERS') && (
                            <div className="col-span-12">
                                <DashCustomers />
                            </div>
                        )}
                    </div>
                </div>
            </React.Suspense>
        </React.Fragment>
    );
}

export default Dashboard;