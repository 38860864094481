import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loading } from 'components';

const Users = React.lazy(() => import('./Users'));
const Roles = React.lazy(() => import('./Roles'));

const Team = () => {

    const routes = [
        { path: '', element: Users, },
        { path: 'roles', element: Roles, },
    ];

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <Routes>
                    {routes.map(route => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={<route.element />}
                        />
                    ))}
                </Routes>
            </React.Suspense>
        </React.Fragment>
    );
}

export default Team;