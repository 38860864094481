import React from 'react';
import { Button, Drawer, Form, Input, notification, Select } from 'antd';
import { helpers, images, types } from 'helpers';
import { useAddBusinessMutation, useEditBusinessMutation } from 'Store/api/auth.api';
import { useDispatch } from 'react-redux';
import { _setUtils } from 'Store/slices/utils.slice';

interface Props {
    row?: types.Business;
    open: boolean;
    type: string;
    onClose: () => void;
    onSuccess?: (e: types.Business) => void;
}
const FormBusiness = ({ open, row, type, onClose, onSuccess }: Props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [addBusiness, { isLoading: isAbLoading, isError: isAbError, isSuccess: isAbSuccess, error: abError, data: abData }] = useAddBusinessMutation();
    const [editBusiness, { isLoading: isEbLoading, isError: isEbError, isSuccess: isEbSuccess, error: ebError, data: ebData }] = useEditBusinessMutation();

    const data = abData || ebData;
    const error = abError || ebError;
    const isError = isAbError || isEbError;
    const isLoading = isAbLoading || isEbLoading;
    const isSuccess = isAbSuccess || isEbSuccess;

    React.useEffect(() => {
        if (row?.id && open) {
            form.setFieldsValue(row);
        } else {
            form.resetFields();
        }
        // eslint-disable-next-line
    }, [row, open]);

    React.useEffect(() => {
        isError && notification.error({ message: (error as any).data?.message || ((error as any).data || []).join(', ') });
        if (isSuccess) {
            notification.success({ message: 'Business changes saved' });
            dispatch(_setUtils({ key: 'meCache', value: data?.updated_at }));
            onSuccess && onSuccess(data as types.Business);
            onClose();
        }
        // eslint-disable-next-line
    }, [isError, isSuccess]);

    const submit = async (v: Partial<types.Business>) => {
        v['id'] = row?.id;
        v['preferences'] = { ...row?.preferences, ...v.preferences } as any;
        v['callback_url'] = row?.callback_url || 'https://google.com';
        if (row?.id) {
            await editBusiness(v);
        } else {
            await addBusiness(v);
        }
    }

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={480}
                closable={true}
                onClose={onClose}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={submit}>
                    <div className="flex justify-between items-center mb-8">
                        <div className="text-xl font-semibold">
                            {row?.id ? `Edit merchant's ${type}` : `Add new merchant`}
                        </div>
                        <div
                            className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                            onClick={onClose}
                        >
                            <img src={images.Close} alt="Close" />
                        </div>
                    </div>

                    {type === 'details' && (
                        <div className="grid grid-cols-2 gap-4">
                            <div className="col-span-2">
                                <Form.Item name="name" label="Business name" rules={helpers.requiredInput}>
                                    <Input size="large" type="text" placeholder="Company name" autoComplete="off" disabled={isLoading} />
                                </Form.Item>
                            </div>
                            <div className="col-span-2">
                                <Form.Item name="description" label="Describe your business" rules={helpers.requiredInput}>
                                    <Input.TextArea size="large" rows={4} placeholder="Start typing..." autoComplete="off" disabled={isLoading} />
                                </Form.Item>
                            </div>
                            <div className="col-span-1">
                                <Form.Item name="industry" label="Industry" className="mb-2" rules={helpers.requiredInput}>
                                    <Select size="large" showSearch optionFilterProp="children" placeholder="Select industry" disabled={isLoading}>
                                        {Object.keys(types.BusinessIndustries).map(option => (
                                            <Select.Option key={option} value={option}>
                                                {types.BusinessIndustries[option as keyof typeof types.BusinessIndustries]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-span-1">
                                <Form.Item name="team_size" label="Team size" className="mb-2" rules={helpers.requiredInput}>
                                    <Select size="large" placeholder="Select team size" disabled={isLoading}>
                                        {Object.keys(types.BusinessTeamSizes).map(option => (
                                            <Select.Option key={option} value={option}>
                                                {types.BusinessTeamSizes[option as keyof typeof types.BusinessTeamSizes]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    )}

                    {type === 'fees' && (
                        <div>
                            {(row?.wallets || []).map((wall, i) => (
                                <div key={wall.currency} className="grid grid-cols-2 gap-4">
                                    {((types.feesAddOrPercentage[wall.currency] || {})['DEBIT'] || []).includes('percentage') && (
                                        <div className="col-span-1">
                                            <Form.Item
                                                name={['preferences', 'fees', wall.currency, 'fee_debit_percentage']}
                                                label={`${wall.currency}: Debit percent`} className="mb-2"
                                            >
                                                <Input size="large" type="number" suffix="%" autoComplete="off" disabled={isLoading} />
                                            </Form.Item>
                                        </div>
                                    )}
                                    {((types.feesAddOrPercentage[wall.currency] || {})['CARD'] || []).includes('percentage') && (
                                        <div className="col-span-1">
                                            <Form.Item
                                                name={['preferences', 'fees', wall.currency, 'fee_card_percentage']}
                                                label={`${wall.currency}: Card percent`} className="mb-2"
                                            >
                                                <Input size="large" type="number" suffix="%" autoComplete="off" disabled={isLoading} />
                                            </Form.Item>
                                        </div>
                                    )}
                                    {((types.feesAddOrPercentage[wall.currency] || {})['DEBIT'] || []).includes('add') && (
                                        <div className="col-span-1">
                                            <Form.Item
                                                name={['preferences', 'fees', wall.currency, 'fee_debit_add']}
                                                label={`${wall.currency}: Debit add`} className="mb-2"
                                            >
                                                <Input size="large" type="number" addonBefore={`+${wall.currency}`} autoComplete="off" disabled={isLoading} />
                                            </Form.Item>
                                        </div>
                                    )}
                                    <div className="col-span-1">
                                        <Form.Item
                                            name={['preferences', 'fees', wall.currency, 'fee_debit_max']}
                                            label={`${wall.currency}: Debit max`} className="mb-2"
                                        >
                                            <Input size="large" type="number" addonBefore={wall.currency} autoComplete="off" disabled={isLoading} />
                                        </Form.Item>
                                    </div>

                                    {((types.feesAddOrPercentage[wall.currency] || {})['CREDIT'] || []).includes('percentage') && (
                                        <div className="col-span-1">
                                            <Form.Item
                                                name={['preferences', 'fees', wall.currency, 'fee_credit_percentage']}
                                                label={`${wall.currency}:  Credit percent`} className="mb-2"
                                            >
                                                <Input size="large" type="number" suffix="%" autoComplete="off" disabled={isLoading} />
                                            </Form.Item>
                                        </div>
                                    )}
                                    {((types.feesAddOrPercentage[wall.currency] || {})['CREDIT'] || []).includes('add') && (
                                        <div className="col-span-1">
                                            <Form.Item
                                                name={['preferences', 'fees', wall.currency, 'fee_credit_add']}
                                                label={`${wall.currency}:  Credit add`} className="mb-2"
                                            >
                                                <Input size="large" type="number" addonBefore={`+${wall.currency}`} autoComplete="off" disabled={isLoading} />
                                            </Form.Item>
                                        </div>
                                    )}
                                    <div className="col-span-1">
                                        <Form.Item
                                            name={['preferences', 'fees', wall.currency, 'fee_credit_max']}
                                            label={`${wall.currency}:  Credit max`} className="mb-2"
                                        >
                                            <Input size="large" type="number" addonBefore={wall.currency} autoComplete="off" disabled={isLoading} />
                                        </Form.Item>
                                    </div>
                                    {i + 1 < (row?.wallets || []).length && (
                                        <div className="col-span-2 border-b mb-4" />
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    {/* <Collapse accordion defaultActiveKey={['1']}>
                        <Collapse.Panel header="Basic details" key="1">

                        </Collapse.Panel>
                        <Collapse.Panel header="Fees configuration" key="2">

                        </Collapse.Panel>
                    </Collapse> */}

                    <div className="mt-8">
                        <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default FormBusiness;