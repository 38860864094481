import React from 'react';
import { axius, helpers, types } from 'helpers';
import moment from 'moment';
import _Store from '_Store';

interface Props {
    onChange?: (e: types.TrxProviders) => void;
}
const Volumes = (props: Props) => {

    const { _store: { _auth: { user } } } = React.useContext(_Store);

    const [data, setData] = React.useState<types.Volume[]>([]);
    const [provider, setProvider] = React.useState<types.TrxProviders>();

    React.useEffect(() => {
        axius.get(`transactions/volumes`, { date: moment().format('YYYY-MM-DD') }).then(res => {
            setData(res.status === 200 ? res.data : []);
        });
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        provider && props.onChange && props.onChange(provider);
        // eslint-disable-next-line
    }, [provider]);

    return (
        <React.Fragment>
            {(user.admin_role?.business || []).length === 0 && (
                <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-4">
                    {data.map(volume => (
                        <div
                            key={volume.id}
                            className={`bg-white rounded-xl border p-6 ${props.onChange ? 'cursor-pointer' : ''} ${provider === volume.provider ? 'border-primary shadow-md' : ''}`}
                            onClick={() => props.onChange && setProvider(volume.provider)}
                        >
                            <div className="text-gray-500 mb-2 flex items-center">
                                {/* <img src={datatables.findCurrencyImage(volume.currency)} alt={volume.currency} className="mr-2 rounded" /> */}
                                {helpers.ucFirst(volume.provider)}
                            </div>
                            <div className="text-2xl font-semibold">{helpers.format.currency(volume.amount, volume.currency)}</div>
                        </div>
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};

export default Volumes;