import React from 'react';
import { helpers, types } from 'helpers';

const Store = React.createContext({} as types.StoreInterface);

export const StoreProvider = (props: {
    children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {

    const [user, setUser] = React.useState<types.User>(helpers.getStorageJson('user') || {});
    const [token, setToken] = React.useState(helpers.getStorage('token'));
    const [_utils, setUtils] = React.useState({
        env: (helpers.getStorage('env') || 'live') as 'test' | 'live',
        limit: 12,
        loading: {
            primary: '',
            spinning: false,
            secondary: '',
        }
    });
    const [_data, setData] = React.useState({
        key: ((helpers.getStorageJson('business') || { keys: [] }) as types.Business).keys[0],
        business: (helpers.getStorageJson('business') || {}) as types.Business,
    });

    const _store = { _auth: { user, token }, _utils, _data };

    const _setUser = (value: types.User) => {
        const newVlaue = { ...user, ...value };
        setUser(newVlaue);
        helpers.setStorageJson('user', newVlaue);
    }

    const _setToken = (value: string) => {
        setToken(value);
        helpers.setStorage('token', value);
    }

    const _setAuth = (value: { user: types.User; token: string }) => {
        _setUser(value.user);
        _setToken(value.token);
        helpers.setStorage('token', value.token);
        helpers.setStorageJson('user', value.user);
    }

    const _setUtils = (payload: { key: string, value: any }) => {
        const { key, value } = payload;
        if (typeof value === 'string') {
            helpers.setStorage(key, value);
            setUtils((prevState) => ({ ...prevState, [key]: value, }));
        } else {
            helpers.setStorageJson(key, value);
            setUtils((prevState) => ({ ...prevState, [key]: { ...prevState[key as keyof typeof prevState] as object, ...value }, }));
        }
    }

    const _setData = (payload: { key: string, value: any }) => {
        const { key, value } = payload;
        if (typeof value === 'string') {
            helpers.setStorage(key, value);
            setData((prevState) => ({ ...prevState, [key]: value, }));
        } else {
            helpers.setStorageJson(key, value);
            setData((prevState) => ({ ...prevState, [key]: { ...prevState[key as keyof typeof prevState] as object, ...value }, }));
        }
    }

    return (
        <Store.Provider value={{ _store, _setAuth, _setUser, _setToken, _setUtils, _setData }}>
            {props.children}
        </Store.Provider>
    );

}

export default Store;