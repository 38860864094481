import React from 'react';
import { axius, datatables, helpers, types } from 'helpers';
import { ExportData, Loading, Pagination, Table } from 'components';
import { useTypedSelector } from 'Store/_Store';
import { Button, Input, Modal, notification, Select, Tag } from 'antd';
import TransactionDetails from './Transactions/TransactionDetails';


interface Filter {
    type: types.TrxTypes.SETTLEMENT;
    apiKey: 'live';
    status?: string,
}
const defaultFilters: Filter = {
    type: types.TrxTypes.SETTLEMENT,
    apiKey: 'live',
    status: types.TrxStatus.INITIATED,
};
const Settlements = () => {

    const { _utils: { limit } } = useTypedSelector((state) => state);

    const [row, setRow] = React.useState<types.Transaction>({} as types.Transaction);
    const [act, setAction] = React.useState<{ trs: types.Transaction; status: types.TrxStatus; reason?: string; } | null>(null);
    const [meta, setMeta] = React.useState({ next: '', previous: '', page: 1, total: 0, });
    const [data, setData] = React.useState<types.Transaction[]>([] as types.Transaction[]);
    const [loading, setLoading] = React.useState(false);
    const [filters, setFilters] = React.useState<Filter>(defaultFilters);

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const getData = (cursor = `0-${limit}`) => {
        setLoading(true);
        if (!filters.status) delete filters.status;
        axius.get('transactions', {
            cursor,
            ...filters,
        }).then(res => {
            if (res.status === 200) {
                setMeta(res.data.meta);
                setData(res.data.data);
            }
            setLoading(false);
        });
    }

    const updateTrs = () => {
        if (act) {
            const { trs: { id }, status } = act;
            axius.put(`transactions/${id}`, { status, rejection_reason: act.reason }).then(res => {
                if (res.status === 200) {
                    const copyData: types.Transaction[] = helpers.copyObject(data);
                    const i = copyData.indexOf(copyData.find(c => c.id === id) as types.Transaction);
                    copyData[i] = res.data;
                    setAction(null);
                    setData(copyData);
                    notification.success({ message: `Settlement ${status === types.TrxStatus.COMPLETED ? 'approved' : 'rejected'} successfully!` });
                }
            });
        }
    }

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <div className="bg-white p-4 flex justify-between items-center rounded-xl border_ shadow-sm_">
                    <div className="grid grid-cols-12 gap-4 w-full">
                        <div className="col-span-2">
                            <Select
                                size="large"
                                showSearch={false}
                                optionFilterProp="children"
                                placeholder="Choose status"
                                disabled={loading}
                                className="w-full"
                                value={filters.status}
                                onChange={(status) => setFilters(sf => ({ ...sf, status }))}
                            >
                                <Select.Option value="">All status</Select.Option>
                                {[types.TrxStatus.INITIATED, types.TrxStatus.COMPLETED, types.TrxStatus.CANCELLED].map(option => (
                                    <Select.Option key={option} value={option}>
                                        {types.TrxStatusLabels[option as keyof typeof types.TrxStatusLabels]}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="flex">
                        {helpers.hasPermission('SETTLEMENT_EXPORT') && (
                            <ExportData
                                name={`Settlements`}
                                mapData={(e: types.Transaction[]) => datatables.Transactions.export(e)}
                                fetchData={{
                                    query: { ...filters, },
                                    endpoint: 'transactions',
                                }}
                            />
                        )}
                    </div>
                </div>

                <div>
                    <div className="rounded-xl mt-8 bg-white border_ shadow-sm_">
                        <div className="font-semibold text-lg p-6">
                            Settlements&nbsp;
                            <Tag color="green">{helpers.format.number(meta.total, 0)}</Tag>
                        </div>
                        <Table
                            loading={loading}
                            heading={['Business', ...datatables.Transactions.heading, '']}
                            data={datatables.Transactions.data(data).map(row => {
                                const trs = data.find(d => d.id === row.id);
                                return {
                                    business: trs?.business_name,
                                    ...row,
                                    actions: {
                                        clickable: false,
                                        value: (
                                            <div className="flex gap-3">
                                                {trs?.status === types.TrxStatus.INITIATED && (
                                                    <>
                                                        <div
                                                            className="h-[35px] w-[35px] flex justify-center items-center border rounded-full hover:bg-green-300 transi"
                                                            onClick={() => setAction({ trs, status: types.TrxStatus.COMPLETED })}
                                                        >
                                                            <span className="material-symbols-outlined">check_circle</span>
                                                        </div>
                                                        <div
                                                            className="h-[35px] w-[35px] flex justify-center items-center border rounded-full hover:bg-red-300 transi"
                                                            onClick={() => setAction({ trs, status: types.TrxStatus.CANCELLED })}
                                                        >
                                                            <span className="material-symbols-outlined">close</span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )
                                    },
                                };
                            })}
                            onClick={(e) => setRow(data.find(row => row.id === e.id) as types.Transaction)}
                        />
                    </div>
                    <div className="bg-white p-4 border_ shadow-sm_ mt-8 rounded-xl">
                        <Pagination meta={meta} navigate={getData} />
                    </div>
                    <TransactionDetails
                        row={row}
                        open={!!(row.id)}
                        onClose={() => {
                            setRow({} as types.Transaction);
                        }}
                    />
                </div>

                <Modal open={!!act} width={450} centered title={null} footer={null} maskClosable={false} onCancel={() => setAction(null)}>
                    <div className="font-semibold text-lg mb-2">{act?.status === types.TrxStatus.COMPLETED ? 'Approve' : 'Reject'} settlement</div>
                    <div>This action is irreversible. Do you wish to continue?</div>
                    {act?.status === types.TrxStatus.CANCELLED && (
                        <Input size="large" placeholder="Rejection reason" className="mt-4" onChange={e => setAction((sa: any) => ({ ...sa, reason: e.target.value }))} />
                    )}

                    <div className="grid grid-cols-12 justify-betweens mt-8">
                        <Button size="large" block className="col-span-4" onClick={() => setAction(null)}>Cancel</Button>
                        <div className="col-span-4" />
                        <Button size="large" block danger={!!(act?.status === types.TrxStatus.CANCELLED)} className="col-span-4" type="primary" onClick={updateTrs}>
                            Yes, {act?.status === types.TrxStatus.COMPLETED ? 'Approve' : 'Reject'}
                        </Button>
                    </div>
                </Modal>
            </React.Suspense>
        </React.Fragment>
    );
}

export default Settlements;