import { createSlice } from '@reduxjs/toolkit';
import { transactionApi } from '../api/transaction.api';
import { types } from 'helpers';

interface TransactionState {
    meta: types.DataMeta;
    data: types.Transaction[];
}

const initialState: TransactionState = {
    meta: {} as types.DataMeta,
    data: [],
};

export const transactionSlice = createSlice({
    name: '_transactions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            transactionApi.endpoints.allTransactions.matchFulfilled,
            (state, action) => {
                state.meta = action.payload.meta;
                state.data = action.payload.data;
            }
        );
    }
});