import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { authApi } from './api/auth.api';
import { utilsApi } from './api/utils.api';
import { transactionApi } from './api/transaction.api';
import { unauthenticatedMiddleware } from './middleware/unauthenticatedMiddleware';
import { rootReducer, RootState } from './reducer';

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(unauthenticatedMiddleware)
            .concat(authApi.middleware)
            .concat(utilsApi.middleware)
            .concat(transactionApi.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
