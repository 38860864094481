import React from 'react';
import { helpers } from 'helpers';
import { useTypedSelector } from 'Store/_Store';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
    route: {
        auth: boolean;
        path: string;
        types?: string[];
        element: any;
    };
    children: any;
}
const Raute = ({ route, children }: Props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const { _auth: { user, token } } = useTypedSelector((state) => state);

    const isLoggedIn = !!(user.id && token && !location.pathname.includes('auth'));
    const isAuthorized = route.auth === isLoggedIn;
    const defaultPassword = helpers.getStorage('defaultPassword');

    React.useEffect(() => {
        if (isLoggedIn) {
            if (defaultPassword) {
                navigate('/settings/password', { replace: true });
            }
        } else {
            !location.pathname.includes('auth') && navigate(`/auth/login`, { replace: true });
        }
        // eslint-disable-next-line 
    }, [route, isAuthorized]);

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default Raute;