import React from 'react';
import { Layout, Switch } from 'antd';
import { helpers, images, types } from 'helpers';
import { useTypedSelector } from 'Store/_Store';
import { _setUtils } from 'Store/slices/utils.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const Header = ({ title }: { title: string; }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { _auth: { user, business }, _utils: { env, isMobileMenuVisible } } = useTypedSelector((state) => state);

  return (
    <React.Fragment>
      <Layout.Header className="shadow-mds mt-4" style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', background: '#F5F5F5', }}>
        <div id="header_desktop" className="flex justify-between h-full">
          <div className="font-bold" style={{ fontSize: 25 }}>{title}</div>
          <div className="flex items-center">

            {user?.type === types.UserTypes.USER && (
              <div className="mr-8 bg-white rounded-lg px-4 py-3 flex items-center border border-gray-200">
                <div className="mr-2 font-semibold leading-none capitalize">{env}</div>
                <Switch
                  checked={!!(env === 'live')}
                  disabled={!!(business.status !== types.TeamStatus.ACCEPTED)}
                  onChange={e => {
                    dispatch(_setUtils({ key: 'env', value: e ? 'live' : 'test' }));
                    helpers.redirect('./');
                  }}
                />
              </div>
            )}

            {user?.type === types.UserTypes.USER && (
              <div className="cursor-pointer" onClick={() => navigate('/settings')}>
                <img src={images.Settings} alt="Settings" />
              </div>
            )}
          </div>
        </div>

        <div id="header_mobile" className="justify-between items-center">
          <img src={images.Logo} alt="Logo" />
          <img
            src={images.Hamburger}
            alt="Hamburger"
            onClick={() => {
              dispatch(_setUtils({ key: 'isMobileMenuVisible', value: !isMobileMenuVisible }));
            }}
          />
        </div>
      </Layout.Header>
    </React.Fragment>
  );
};
export default Header;
