import React from 'react';
import { Button, Drawer, Form, Input, notification, Select } from 'antd';
import { images, types } from 'helpers';
import { useEditBusinessMutation } from 'Store/api/auth.api';
import { useDispatch } from 'react-redux';
import { CheckCircle, NotInterested } from '@mui/icons-material';
import { _setUtils } from 'Store/slices/utils.slice';
import Status from './Status';

interface Props {
    row: types.Business;
    onSuccess?: (e: types.Business) => void;
}
const FormBusinessStatus = ({ row, onSuccess }: Props) => {

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const [editBusiness, { isLoading, isError, isSuccess, error, data }] = useEditBusinessMutation();

    React.useEffect(() => {
        isError && notification.error({ message: (error as any).data?.message || ((error as any).data || []).join(', ') });
        if (isSuccess) {
            notification.success({ message: 'Business status saved' });
            onSuccess && onSuccess(data as types.Business);
        }
        // eslint-disable-next-line
    }, [isError, isSuccess]);

    React.useEffect(() => {
        dispatch(_setUtils({ key: 'loading', value: { spinning: isLoading, primary: `Changing business status`, } }));
        // eslint-disable-next-line
    }, [isLoading]);

    const submit = async (v: any) => {
        row['callback_url'] = row?.callback_url || 'https://google.com';
        await editBusiness({ ...row, status: (v.rejection_reason ? types.TeamStatus.REJECTED : types.TeamStatus.ACCEPTED), rejection_reason: v.rejection_reason });
    }

    const close = () => {
        setOpen(false);
    }

    const rules = [{ required: true, message: 'This field is required' }];

    return (
        <React.Fragment>
            <div className="w-12/12">
                {row.status === types.TeamStatus.PENDING ? (
                    <div>
                        <Select
                            value={row.status} className="hidden"
                            style={{ width: '100%' }}
                            onChange={submit}
                            options={[
                                { label: 'Pending', value: types.TeamStatus.PENDING },
                                { label: 'Approve', value: types.TeamStatus.ACCEPTED },
                                { label: 'Reject', value: types.TeamStatus.REJECTED },
                            ]}
                        />

                        <div className="flex gap-4">
                            <Button size="large" type="primary" className="flex justify-center items-center gap-2" onClick={() => submit(types.TeamStatus.ACCEPTED)}><CheckCircle /> Approve</Button>
                            <Button size="large" type="primary" danger className="flex justify-center items-center gap-2" onClick={() => setOpen(true)}><NotInterested /> Reject</Button>
                        </div>
                    </div>
                ) : <Status status={row.status} label={types.TeamStatusLabels[row.status]} />}
            </div>


            <Drawer
                open={open}
                title={null}
                width={480}
                closable={true}
                onClose={close}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={submit}>
                    <div className="flex justify-between items-center mb-8">
                        <div className="text-xl font-semibold">
                            Reject business
                        </div>
                        <div
                            className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                            onClick={close}
                        >
                            <img src={images.Close} alt="Close" />
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-2">
                            {/*  help="The user will receive an email with reason for rejection" */}
                            <Form.Item name="rejection_reason" label="State your reason for rejection" rules={rules}>
                                <Input.TextArea rows={4} size="large" autoComplete="off" disabled={isLoading} />
                            </Form.Item>
                        </div>
                        <div className="col-span-2 mt-8">
                            <Button type="primary" size="large" htmlType="submit" block loading={isLoading}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default FormBusinessStatus;