import React from 'react';
import { axius, datatables, helpers, types } from 'helpers';
import { Loading, Pagination, Table } from 'components';
import { useTypedSelector } from 'Store/_Store';
import { DatePicker, Tag } from 'antd';
import dayjs from 'dayjs';


interface Filter {
    date_to: string;
    date_from: string;
}
const Statement = () => {

    const { _utils: { limit } } = useTypedSelector((state) => state);

    const [meta, setMeta] = React.useState({ next: '', previous: '', page: 1, total: 0, });
    const [data, setData] = React.useState<types.Logs[]>([] as types.Logs[]);
    const [loading, setLoading] = React.useState(false);
    const [filters, setFilters] = React.useState<Filter>({ date_from: dayjs().startOf('M').format('YYYY-MM-DD'), date_to: dayjs().format('YYYY-MM-DD'), });

    React.useEffect(() => {
        filters.date_from && filters.date_to && getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const getData = (cursor = `0-${limit}`) => {
        setLoading(true);
        axius.get('logs', {
            cursor,
            date_to: filters.date_to || '',
            date_from: filters.date_from || '',
        }).then(res => {
            if (res.status === 200) {
                setMeta(res.data.meta);
                setData(res.data.data);
            }
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <div className="bg-white p-4 flex justify-between items-center rounded-xl border_ shadow-sm_">
                    <div className="grid grid-cols-12 gap-4 w-full">
                        <div className="col-span-4 xl:col-span-3">
                            <DatePicker.RangePicker
                                size="large"
                                value={[dayjs(filters.date_from), dayjs(filters.date_to)]}
                                onChange={e => {
                                    if (e) {
                                        setFilters(sf => ({
                                            ...sf,
                                            date_to: e[1]?.format('YYYY-MM-DD') || '',
                                            date_from: e[0]?.format('YYYY-MM-DD') || '',
                                        }));
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                {filters.date_from && filters.date_to ? (
                    <div>
                        <div className="rounded-xl mt-8 bg-white border_ shadow-sm_">
                            <div className="font-semibold text-lg p-6">
                                Statement&nbsp;
                                <Tag color="green">{helpers.format.number(meta.total, 0)}</Tag>
                            </div>
                            <Table
                                loading={loading}
                                heading={datatables.Logs.heading}
                                data={datatables.Logs.data(data)}
                            />
                        </div>
                        <div className="bg-white p-4 border_ shadow-sm_ mt-8 rounded-xl">
                            <Pagination meta={meta} navigate={getData} />
                        </div>
                    </div>
                ) : (
                    <div className="bg-white p-4 border_ shadow-sm_ mt-8 rounded-xl text-center">
                        Please apply the filters above
                    </div>
                )}
            </React.Suspense>
        </React.Fragment>
    );
}

export default Statement;