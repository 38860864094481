import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config, types } from 'helpers';
import { RootState } from '../reducer';


export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.server,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState)._auth.token;
      if (token) {
        headers.set('Authorization', `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    login: build.mutation<
      { data: types.User; token: string; },
      { email: string; password: string; type: string; }
    >({
      query: (body) => ({
        url: `auth/login`,
        method: 'POST',
        body,
      }),
    }),
    loginOtp: build.mutation<
      { token: string; },
      { email: string; code: string; }
    >({
      query: (body) => ({
        url: `auth/otp`,
        method: 'POST',
        body,
      }),
    }),
    reset: build.mutation<
      { message: string; },
      { email: string; }
    >({
      query: (body) => ({
        url: `auth/reset`,
        method: 'POST',
        body,
      }),
    }),
    resetConfirm: build.mutation<
      { message: string },
      { code: number; email: string; password: string; }
    >({
      query: (body) => ({
        url: `auth/reset`,
        method: 'PUT',
        body,
      }),
    }),
    signup: build.mutation<{ user: types.User; token: string; business: types.Business }, Partial<types.User>>({
      query: (body) => ({
        url: `auth/signup`,
        method: 'POST',
        body,
      }),
    }),
    acceptInvite: build.mutation<{ data: types.User; token: string; business: types.Business }, Partial<types.User>>({
      query: (body) => ({
        url: `business/invites/${body.id}`,
        method: 'POST',
        body,
      }),
    }),
    me: build.query<types.User, { id: string; cache?: string; }>({
      query: (query) => `users/${query.id}?cache=${query.cache}`,
    }),
    editUser: build.mutation<types.User, Partial<types.User>>({
      query: (body) => ({
        url: `users/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    editPassword: build.mutation<types.User, { id: string; old_password: string; new_password: string; }>({
      query: (body) => ({
        url: `users/${body.id}/password`,
        method: 'PUT',
        body,
      }),
    }),

    business: build.query<types.Business, { id: string; cache?: string; }>({
      query: (query) => `business/${query.id}?cache=${query.cache}`,
    }),
    addBusiness: build.mutation<types.Business, Partial<types.Business>>({
      query: (body) => ({
        url: `business`,
        method: 'POST',
        body,
      }),
    }),
    editBusiness: build.mutation<types.Business, Partial<types.Business & { rejection_reason?: string }>>({
      query: (body) => ({
        url: `business/${body.id}`,
        method: 'PUT',
        body,
      }),
    }),
    topUpBusiness: build.mutation<types.Business, { id: string; amount: number }>({
      query: (body) => ({
        url: `business/${body.id}/topup`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLoginOtpMutation,
  useResetMutation,
  useResetConfirmMutation,
  useSignupMutation,
  useAcceptInviteMutation,
  useMeQuery,
  useLazyMeQuery,
  useBusinessQuery,
  useLazyBusinessQuery,
  useEditUserMutation,
  useEditPasswordMutation,
  useEditBusinessMutation,
  useAddBusinessMutation,
  useTopUpBusinessMutation,
} = authApi;
