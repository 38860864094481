import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loading } from 'components';

const Details = React.lazy(() => import('./BusinessDetails'));
const Balances = React.lazy(() => import('./BusinessBalances'));

const Business = () => {

    const routes = [
        { path: `balances`, element: Balances, },
        { path: `:id`, element: Details, },
    ]

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loading spinning={true} />}>
                <Routes>
                    <Route>
                        {routes.map(route => (
                            <Route key={route.path} path={route.path} element={<route.element />} />
                        ))}
                    </Route>
                </Routes>
            </React.Suspense>
        </React.Fragment>
    );
}

export default Business;