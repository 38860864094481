import React from 'react';
import { datatables, helpers, types } from 'helpers';

interface Props {
    onChange?: (e: types.Currencies) => void;
    business: { wallets: types.Wallet[]; };
}
const Balances = (props: Props) => {

    const [currency, setCurrency] = React.useState<types.Currencies>();

    React.useEffect(() => {
        currency && props.onChange && props.onChange(currency);
        // eslint-disable-next-line
    }, [currency]);

    return (
        <React.Fragment>
            {(props.business.wallets || []).length > 0 && (
                <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-4 mb-4">
                    {props.business.wallets.map(wallet => (
                        <div
                            key={wallet.id}
                            className={`bg-white rounded-xl border_ p-6 ${props.onChange ? 'cursor-pointer hover:shadow-xl border-white' : ''} ${currency === wallet.currency ? 'border-primary shadow-md' : ''}`}
                            onClick={() => props.onChange && setCurrency(wallet.currency)}
                        >
                            <div className="text-gray-500 mb-2 flex items-center text-sm gap-2">
                                <img src={datatables.findCurrencyImage(wallet.currency)} alt={wallet.currency} className="rounded h-[12px]" />
                                {types.CurrenciesLabels[wallet.currency]}
                            </div>
                            <div className="text-xl font-bold">{helpers.format.currency(wallet.balance, wallet.currency)}</div>
                            <div className="text-sm font-semibold text-red-500 mt-3">
                                {wallet.balance_hold && wallet.balance_hold > 0 ? helpers.format.currency(wallet.balance_hold, wallet.currency) : '0.00'}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};

export default Balances;